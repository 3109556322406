import { render, staticRenderFns } from "./CoverWidget.vue?vue&type=template&id=4e5c29ed&scoped=true&"
import script from "./CoverWidget.vue?vue&type=script&lang=js&"
export * from "./CoverWidget.vue?vue&type=script&lang=js&"
import style0 from "./CoverWidget.vue?vue&type=style&index=0&id=4e5c29ed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5c29ed",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VAlert,VAvatar,VCardText,VIcon,VImg,VRating})
